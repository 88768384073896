export const routes = [
  {
    path: '/configure',
    /* internal name: 'configure' */
    /* no component */
    children: [
      {
        path: ':section',
        name: 'configure-:section',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/configure/[section].vue'),
        /* no children */
        meta: {
          "titles": {
            "de": "Konfigurieren",
            "en": "Configure"
          },
          "locales": [
            "configure"
          ]
        }
      }
    ],
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/export.vue'),
    /* no children */
    meta: {
      "titles": {
        "de": "Umwelt Export",
        "en": "Environmental Export"
      },
      "locales": [
        "esgExport"
      ]
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/help.vue'),
    /* no children */
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/home.vue'),
    /* no children */
    meta: {
      "titles": {
        "de": "Home",
        "en": "Home"
      },
      "locales": [
        "home"
      ]
    }
  },
  {
    path: '/improve',
    name: 'improve',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/improve.vue'),
    children: [
      {
        path: 'goals',
        name: 'improve-goals',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/improve/goals.vue'),
        /* no children */
        meta: {
          "locales": [
            "improve"
          ]
        }
      },
      {
        path: 'measures/:code?',
        name: 'improve-measures',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/improve/measures.[[code]].vue'),
        /* no children */
        meta: {
          "locales": [
            "improve"
          ]
        }
      }
    ],
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/licenses.vue'),
    /* no children */
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/login.vue'),
    /* no children */
    meta: {
      "hideMenu": true,
      "locales": [
        "login"
      ]
    }
  },
  {
    path: '/measure',
    name: 'measure',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure.vue'),
    children: [
      {
        path: 'activities',
        /* internal name: 'measure-activities' */
        /* no component */
        children: [
          {
            path: '',
            name: 'measure-activities',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/activities/index.vue'),
            /* no children */
            meta: {
              "locales": [
                "measure",
                "performance"
              ]
            }
          },
          {
            path: ':category/:uuid/edit',
            name: 'measure-activities-:category-:uuid-edit',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/activities/[category].[uuid].edit.vue'),
            /* no children */
            meta: {
              "locales": [
                "measure",
                "configure"
              ]
            }
          }
        ],
      },
      {
        path: 'activity-history',
        /* internal name: 'measure-activity-history' */
        /* no component */
        children: [
          {
            path: '',
            name: 'measure-activity-history',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/activity-history/index.vue'),
            /* no children */
            meta: {
              "locales": [
                "measure",
                "performance"
              ]
            }
          }
        ],
      },
      {
        path: 'history',
        name: 'measure-history',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/history.vue'),
        children: [
          {
            path: 'invoices',
            name: 'measure-history-invoices',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/history/invoices.vue'),
            /* no children */
            meta: {
              "locales": [
                "measure"
              ]
            }
          },
          {
            path: 'spreadsheets',
            name: 'measure-history-spreadsheets',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/measure/history/spreadsheets.vue'),
            /* no children */
            meta: {
              "locales": [
                "measure"
              ]
            }
          }
        ],
        meta: {
          "locales": [
            "measure"
          ]
        }
      }
    ],
    meta: {
      "locales": [
        "measure"
      ]
    }
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance.vue'),
    children: [
      {
        path: 'environmental',
        /* internal name: 'performance-environmental' */
        /* no component */
        children: [
          {
            path: '',
            name: 'performance-environmental',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/environmental/index.vue'),
            /* no children */
            meta: {
              "titles": {
                "de": "Umwelt",
                "en": "Environmental"
              }
            }
          },
          {
            path: ':code',
            name: 'performance-environmental-:code',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/environmental/[code].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'general',
        /* internal name: 'performance-general' */
        /* no component */
        children: [
          {
            path: '',
            name: 'performance-general',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/general/index.vue'),
            /* no children */
            meta: {
              "titles": {
                "de": "Allgemein",
                "en": "General"
              }
            }
          },
          {
            path: ':code',
            name: 'performance-general-:code',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/general/[code].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'governance',
        /* internal name: 'performance-governance' */
        /* no component */
        children: [
          {
            path: '',
            name: 'performance-governance',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/governance/index.vue'),
            /* no children */
            meta: {
              "titles": {
                "de": "Governance",
                "en": "Governance"
              }
            }
          },
          {
            path: ':code',
            name: 'performance-governance-:code',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/governance/[code].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'social',
        /* internal name: 'performance-social' */
        /* no component */
        children: [
          {
            path: '',
            name: 'performance-social',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/social/index.vue'),
            /* no children */
            meta: {
              "titles": {
                "de": "Sozial",
                "en": "Social"
              }
            }
          },
          {
            path: ':code',
            name: 'performance-social-:code',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/performance/social/[code].vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "titles": {
        "de": "Performance",
        "en": "Performance"
      },
      "locales": [
        "performance"
      ]
    }
  },
  {
    path: '/report',
    /* internal name: 'report' */
    /* no component */
    children: [
      {
        path: '',
        name: 'report',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/index.vue'),
        /* no children */
        meta: {
          "locales": [
            "report"
          ]
        }
      },
      {
        path: ':id',
        /* internal name: 'report-:id' */
        /* no component */
        children: [
          {
            path: ':standardId',
            name: 'report-:id-:standardid',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/[id]/[standardId].vue'),
            /* no children */
            meta: {
              "locales": [
                "report"
              ]
            }
          }
        ],
      },
      {
        path: 'exports',
        /* internal name: 'report-exports' */
        /* no component */
        children: [
          {
            path: ':id',
            /* internal name: 'report-exports-:id' */
            /* no component */
            children: [
              {
                path: ':standardId',
                name: 'report-exports-:id-:standardid',
                component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/exports/[id]/[standardId].vue'),
                /* no children */
                meta: {
                  "locales": [
                    "report"
                  ]
                }
              }
            ],
          }
        ],
      },
      {
        path: 'materiality',
        name: 'report-materiality',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/materiality.vue'),
        children: [
          {
            path: 'impacts',
            name: 'report-materiality-impacts',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/materiality/impacts.vue'),
            /* no children */
          },
          {
            path: 'overview',
            name: 'report-materiality-overview',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/materiality/overview.vue'),
            /* no children */
          },
          {
            path: 'risks-opportunities',
            name: 'report-materiality-risks-opportunities',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/materiality/risks-opportunities.vue'),
            /* no children */
          },
          {
            path: 'stakeholders',
            name: 'report-materiality-stakeholders',
            component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/report/materiality/stakeholders.vue'),
            /* no children */
          }
        ],
        meta: {
          "locales": [
            "materiality"
          ]
        }
      }
    ],
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/reset-password.vue'),
    /* no children */
    meta: {
      "hideMenu": true,
      "locales": [
        "login"
      ]
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/signup.vue'),
    /* no children */
    meta: {
      "hideMenu": true,
      "locales": [
        "login"
      ]
    }
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/strategy.vue'),
    /* no children */
  },
  {
    path: '/supply-chain',
    name: 'supply-chain',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/supply-chain.vue'),
    children: [
      {
        path: 'risks',
        name: 'supply-chain-risks',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/supply-chain/risks.vue'),
        /* no children */
      },
      {
        path: 'suppliers',
        name: 'supply-chain-suppliers',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/supply-chain/suppliers.vue'),
        /* no children */
      }
    ],
    meta: {
      "locales": [
        "supplyChain"
      ]
    }
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/survey.vue'),
    /* no children */
    meta: {
      "hideMenu": true,
      "locales": [
        "survey"
      ]
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/tasks.vue'),
    children: [
      {
        path: 'board',
        name: 'tasks-board',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/tasks/board.vue'),
        /* no children */
        meta: {
          "locales": [
            "tasks"
          ]
        }
      },
      {
        path: 'my-tasks',
        name: 'tasks-my-tasks',
        component: () => import('/codebuild/output/src1822128025/src/CodeGaia-WebApp/src/pages/tasks/my-tasks.vue'),
        /* no children */
        meta: {
          "locales": [
            "tasks"
          ]
        }
      }
    ],
    meta: {
      "titles": {
        "de": "Aufgaben",
        "en": "Tasks"
      },
      "locales": [
        "tasks"
      ]
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

