import { createApp, type Ref } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import * as Sentry from '@sentry/vue'
import { handleHotUpdate } from 'vue-router/auto-routes'

import { useStorage } from '@vueuse/core'
import { navigatorDetector } from 'typesafe-i18n/detectors'
import { detectLocale } from './i18n/i18n-util'
import { i18nPlugin } from './i18n/i18n-vue'
import { loadLocaleAsync } from './i18n/i18n-util.async'
import { spreadTheLocaleNews } from './composables/useLocales'
import type { Locales } from './i18n/i18n-types'

import { formKitPlugin, formKitConfig } from './formkit'
import useFeatureFlags from './composables/useFeatureFlags'
import createRouter from './router'
import App from './App.vue'

import './assets/main.css'

const app = createApp(App)
const router = createRouter()
if (import.meta.hot) handleHotUpdate(router)

// Sentry
if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_ENVIRONMENT,
  })
}

/// Feature flags are reachable as $ff in templates and can be injected as well:
///   import { inject } from 'vue'
///   const ff = inject('feature-flags')
// Important! This needs to be added before everything else,
// so that router and stores can access feature flags as well!
const { flagStore } = useFeatureFlags()
app.provide('feature-flags', flagStore)
app.config.globalProperties.$ff = flagStore

app.use(createPinia())
app.use(router)
app.use(PrimeVue, {
  unstyled: true,
  pt: {
    dialog: {
      mask: {
        class: 'fixed top-0 left-0 right-0 bottom-0 bg-black/50 transition duration-300',
      },
      transition: {
        enterFromClass: 'opacity-0 scale-75 mask-active',
        enterActiveClass: 'transition-all duration-200 ease-out',
        leaveActiveClass: 'transition-all duration-200 ease-out',
        leaveToClass: 'opacity-0 scale-75 mask-active',
      },
      root: {
        class:
          'max-w-[90vw] max-h-[90vh] m-0 rounded-lg shadow-lg ring-0 dark:ring ring-white bg-white dark:bg-black-soft scale-100 text-black dark:text-white',
      },
      header: {
        class: 'flex items-center justify-between shrink-0 gap-2 px-6 pt-6 rounded-t-lg',
      },
      footer: {
        class: 'flex items-center justify-end shrink-0 text-right gap-2 px-4 pb-6 rounded-b-lg',
      },
      title: {
        class: 'font-bold text-lg',
      },
      icons: {
        class: 'flex items-center',
      },
      closeButton: {
        class: 'relative w-10 h-10 flex items-center justify-center rounded-lg bg-gray-btn dark:bg-gray-dark',
      },
      content: {
        class: 'p-6 overflow-x-none overflow-y-auto',
      },
    },
    floatlabel: {
      root: {
        class: [
          'block relative',
          '[&>*:last-child]:text-surface-900/60 dark:[&>*:last-child]:text-white/60',
          '[&>*:last-child]:absolute',
          '[&>*:last-child]:top-1/2',
          '[&>*:last-child]:-translate-y-1/2',
          '[&>*:last-child]:left-3',
          '[&>*:last-child]:pointer-events-none',
          '[&>*:last-child]:transition-all',
          '[&>*:last-child]:duration-200',
          '[&>*:last-child]:ease',
          '[&>*:last-child]:has-[:focus]:-top-3',
          '[&>*:last-child]:has-[:focus]:text-sm',
          '[&>*:last-child]:has-[.filled]:-top-3',
          '[&>*:last-child]:has-[.filled]:text-sm',
        ],
      },
    },
    directives: {
      tooltip: {
        root: {
          class: 'absolute px-1.5 max-w-80',
        },
        arrow: {
          class: 'hidden',
        },
        text: {
          class: [
            'p-3',
            'rounded-lg',
            'bg-white-soft dark:bg-surface-900 dark:text-white',
            'shadow-md',
            'dark:ring-1 dark:ring-inset dark:ring-offset-0 dark:ring-gray',
            'whitespace-pre-line',
            'break-words',
          ],
        },
      },
    },
  },
})
app.directive('tooltip', Tooltip)
app.use(DialogService)
app.use(ConfirmationService)
app.use(ToastService)
app.use(formKitPlugin, formKitConfig)

// detect user's preferred locale
const detectedLocale = detectLocale(navigatorDetector)
const storedLocale = useStorage('language', detectedLocale) as Ref<Locales>
loadLocaleAsync(storedLocale.value).then(() => {
  // activate i18n plugin
  app.use(i18nPlugin, storedLocale.value)
  spreadTheLocaleNews(storedLocale.value, formKitConfig as Record<string, string>)
  app.mount('#app')
})
