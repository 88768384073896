<script setup lang="ts">
import PVButton from 'primevue/button'
import Badge from 'primevue/badge'
import { FormKitIcon } from '@formkit/vue'

type IconPosition = 'left' | 'right' // TODO: | 'top' | 'bottom'
type Size = 'small' | 'large'
export interface Props {
  label?: string
  icon?: string
  iconPos?: IconPosition // defaults to left
  iconOnly?: boolean
  iconClass?: string | object
  iconSecondary?: string
  iconSecondaryClass?: string | object
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  submit?: boolean
  disabled?: boolean
  labelClass?: string
  danger?: boolean
  tight?: boolean
  size?: Size
  noPadding?: boolean
  badge?: string
}

defineProps<Props>()
const ptBadge = {
  root: {
    class: [
      'font-bold',
      'text-xs leading-[1.5rem]',
      'text-center inline-block',
      'p-0 px-1',
      'min-w-[1.5rem] h-[1.5rem]',
      'text-black',
      'bg-gray-border',
      'rounded-full',
    ],
  },
}
</script>
<template>
  <PVButton
    :type="submit ? 'submit' : 'button'"
    :size
    :pt="{
      root: {
        class: [
          'relative',
          'items-center inline-flex text-center align-bottom justify-center',
          iconOnly && !size ? 'w-10 h-10' : noPadding ? 'p-0' : tight ? 'p-2' : 'px-4 py-2',
          primary || submit ? 'text-white' : 'text-black',
          secondary ? 'font-normal' : 'font-medium',
          {
            'text-white bg-primary dark:bg-blue-700 border-primary dark:border-primary': primary || submit,
            'bg-error text-white': danger,
            'bg-white border border-gray-border dark:bg-gray-dark dark:border-gray-light dark:text-white': secondary,
            'text-black dark:text-white font-bold underline': tertiary,
            'border text-black dark:text-white bg-gray-btn dark:bg-gray-dark/50 border-gray-btn dark:border-gray-dark':
              !primary && !submit && !danger && !tertiary && !secondary,
            'cursor-not-allowed opacity-50': disabled,
            'cursor-pointer': !disabled,
          },
          'rounded-lg',
        ],
      },
    }"
    :disabled
  >
    <template #default>
      <FormKitIcon v-if="iconOnly && icon" :icon :class="[iconClass ?? 'w-6 h-6']" />
      <div class="flex items-center" v-else>
        <FormKitIcon
          v-if="icon"
          :icon
          class="w-5 h-5"
          :class="[iconClass, iconPos === 'right' ? 'order-1 ml-2' : 'order-0 mr-2']"
        />
        <div v-if="label" class="font-medium truncate max-w-72" :class="labelClass" :title="label">{{ label }}</div>
        <slot v-else />
        <Badge v-if="badge && badge !== '0'" class="ml-2" :pt="ptBadge" :value="badge" />
        <FormKitIcon v-if="iconSecondary" :icon="iconSecondary" :class="[iconSecondaryClass ?? 'w-5 h-5', 'ml-2']" />
      </div>
    </template>
  </PVButton>
</template>
