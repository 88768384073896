<script setup lang="ts">
import { ref, computed, useTemplateRef } from 'vue'
import DataTable, { type DataTablePageEvent } from 'primevue/datatable'
import Column from 'primevue/column'
import Skeleton from '@component/Skeleton.vue'
import CGButton from '@component/CGButton.vue'
import useLocales from '@composable/useLocales'
import type { DataTablePassThroughOptions } from 'primevue/datatable'
import type { PaginatorPassThroughOptions } from 'primevue/paginator'
import type { ColumnPassThroughOptions } from 'primevue/column'

import { toDateString } from '@/util/formatting'
import type { LocalizedString } from 'typesafe-i18n'

const { LL, locale } = useLocales()

export type TableValue = string | number | Date | boolean | LocalizedString[] | object | null
export type TableRow = Record<string, TableValue>
export type KnownColumnFormatter = 'string' | 'number' | 'date' | 'currency'
export type ColumnFormatterFunc = (v: any, ...options: any) => string
export type Action = {
  action: string
  label?: string
  icon?: string
  iconClass?: string
  iconDisabled?: boolean
}
export type ActionPayload = {
  action: Action['action']
  data: TableRow
  index: number
  event: PointerEvent
}

export interface ColumnDescription {
  header: string // label seen in the header
  field: string // data field to use
  // defaults to string formatter
  formatter?: KnownColumnFormatter | ColumnFormatterFunc
  formatterOptions?: any
  reorder?: boolean
  info?: string
  editable?: boolean
}

export interface Props {
  columns: ColumnDescription[]
  data: TableRow[]
  loading?: boolean
  actions?: Action[]
  totalRecords?: number
  rows?: number
  disabled?: boolean
  reordable?: boolean
  editMode?: 'cell' | 'row'
  selectionMode?: 'single' | 'multiple'
  // columnResizeMode?: 'fit' | 'expand' // TODO: enable column resize when library fixes the issue for unstyled tables
}
export interface Events {
  (e: 'action', value: ActionPayload): void
  (e: 'rowReorder', value: any): void
  (e: 'cellEdit', value: any): void
}
const props = defineProps<Props>()
const emit = defineEmits<Events>()
const selectedRows = defineModel('selection')

const tableEl = useTemplateRef('dt')

function exportCSV() {
  if (!tableEl.value) return
  tableEl.value.exportCSV()
}

// allow access to DataTable export functionality
defineExpose({ exportCSV })

const formattedData = computed(() => {
  return props.data.map((row) => {
    const formattedRow = { ...row }

    props.columns.forEach(({ field, formatter, formatterOptions }) => {
      const value = row[field]

      switch (formatter) {
        case 'date':
          formattedRow[field] = toDateString(value as string, locale.value, formatterOptions)
          break

        case 'currency': // TODO: implement currency formatter
        case 'number': // TODO: implement number formatting, using formatNumber
        default:
          formattedRow[field] = `${value}` // string formatter catch-all
          break
      }
    })

    return formattedRow as Record<keyof TableRow, string>
  })
})

const currentPage = ref(0)

function dataIndex(index: number): number {
  const perPage = props.rows ?? props.totalRecords ?? props.data.length
  return currentPage.value * perPage + index
}

const paginator = computed(() => {
  if (!props.totalRecords || !props.rows) return false
  return props.totalRecords > props.rows
})

function handlePageEvent(event: DataTablePageEvent) {
  currentPage.value = event.page
}

const pt: DataTablePassThroughOptions = {
  root: ({ props }: any) => ({
    class: [
      'relative w-full rounded-lg border border-gray/30',
      { 'flex flex-col h-full': props.scrollable && props.scrollHeight === 'flex' },
    ],
  }),
  table: 'w-full rounded-lg text-black bg-white dark:bg-black dark:text-white',
  thead: 'h-12 bg-white-soft dark:bg-gray-dark',
  column: {
    headercell: {
      class:
        'relative px-2 first:rounded-tl-lg last:rounded-tr-lg last:border-0 last:border-b border-r border-b border-gray/30 text-left',
    },
    bodycell: 'p-0 text-left first:border-l-0 last:border-r-0 border border-b-0 border-gray/30 text-left max-w-xs',
    rowreordericon: {
      class: 'cursor-move',
    },
    rowCheckbox: {
      root: {
        class: 'relative w-6 h-6 ml-2.5 inline-flex align-bottom cursor-pointer select-none',
      },
      box: ({ context }: any) => ({
        class: [
          'flex items-center justify-center w-6 h-6 rounded-md border-2',
          {
            'border-gray-border bg-white-soft dark:bg-black-soft': !context.selected,
            'border-gray-border bg-white dark:bg-black-soft': context.selected,
          },
          {
            'peer-hover:border-gray dark:peer-hover:border-white-soft': !props.disabled && !context.selected,
            'peer-hover:bg-white-soft dark:peer-hover:bg-gray peer-hover:border-blue-600 dark:peer-hover:border-white-soft':
              !props.disabled && context.selected,
            'cursor-default opacity-60': props.disabled,
          },
          'transition-colors duration-200',
        ],
      }),
      input: {
        class:
          'peer w-full  h-full absolute top-0 left-0 z-10 p-0 m-0 opacity-0 rounded-md outline-none border-2 border-gray-border appearance-none cursor-pointer',
      },
      icon: {
        class: 'w-4 h-4 text-base leading-none text-black dark:text-white transition-all duration-200',
      },
    },
    headercontent: 'w-full mx-auto',
    headerCheckbox: {
      root: {
        class: 'relative w-6 h-6 ml-0.5 inline-flex align-bottom cursor-pointer select-none',
      },
      box: ({ context }: any) => ({
        class: [
          'flex items-center justify-center w-6 h-6 rounded-md border-2',
          {
            'border-gray-border bg-white-soft dark:bg-black-soft': !context.selected,
            'border-gray-border bg-white dark:bg-black-soft': context.selected,
          },
          {
            'peer-hover:border-gray dark:peer-hover:border-white-soft': !props.disabled && !context.selected,
            'peer-hover:bg-white-soft dark:peer-hover:bg-gray peer-hover:border-blue-600 dark:peer-hover:border-white-soft':
              !props.disabled && context.selected,
            'cursor-default opacity-60': props.disabled,
          },
          'transition-colors duration-200',
        ],
      }),
      input: {
        class:
          'peer w-full  h-full absolute top-0 left-0 z-10 p-0 m-0 opacity-0 rounded-md outline-none border-2 border-gray-border appearance-none cursor-pointer',
      },
      icon: {
        class: 'w-4 h-4 text-base leading-none text-black dark:text-white transition-all duration-200',
      },
    },
    // TODO: enable column resize when library fixes the issue for unstyled tables
    // columnResizer: {
    //   class: 'block absolute top-0 right-0 w-2 h-full m-0 p-0 border-0 border-transparent hover:border-1 cursor-col-resize',
    // },
  } as ColumnPassThroughOptions,
  paginator: {
    root: {
      class: 'flex items-center justify-start flex-wrap px-4 py-2 border-t border-gray-border',
    },
    previouspagebutton: ({ context }: any) => ({
      class: [
        'relative w-10 h-10 my-1 mx-2 inline-flex items-center justify-center text-black-soft dark:text-white-soft bg-gray-btn dark:bg-black rounded-lg',
        { 'hover:text-blue': !context.disabled },
        { 'opacity-50 cursor-not-allowed': context.disabled },
      ],
    }),
    nextpagebutton: ({ context }: any) => ({
      class: [
        'relative w-10 h-10 my-1 mx-2 inline-flex items-center justify-center text-black-soft dark:text-white-soft bg-gray-btn dark:bg-black rounded-lg',
        { 'hover:text-blue': !context.disabled },
        { 'opacity-50 cursor-not-allowed': context.disabled },
      ],
    }),
    jumptopageinput: {
      input: {
        root: {
          class: 'w-10 h-10 mr-2 px-2 py-1 rounded-lg border border-gray-border bg-white dark:bg-black text-center',
        },
      },
    },
  } as PaginatorPassThroughOptions,
}
</script>

<template>
  <DataTable
    :value="formattedData"
    showGridlines
    :paginator
    paginator-template="PrevPageLink JumpToPageInput CurrentPageReport NextPageLink"
    current-page-report-template=" / {totalPages}"
    :totalRecords
    :rows
    :pt
    :editMode
    v-model:selection="selectedRows"
    @rowReorder="emit('rowReorder', $event)"
    @cellEditComplete="emit('cellEdit', $event)"
    @page="handlePageEvent"
    ref="dt"
  >
    <Column :selectionMode v-if="selectionMode"></Column>
    <Column
      v-for="{ field, header, reorder, info, editable } in columns"
      :rowReorder="reorder"
      :key="field"
      :field="field"
    >
      <template #header>
        <slot name="header" v-bind="{ header, info }">
          <div class="px-2" :title="info">{{ header.replace(/&ZeroWidthSpace;/g, '') }}</div>
        </slot>
      </template>
      <template #body="{ data, field, index }">
        <slot
          name="cell-wrapper"
          v-bind="{ data, field, value: data[field], index, currentPage, dataIndex: dataIndex(index) }"
        >
          <div
            :class="[
              data.id === 'scope-cutoff' ? 'flex flex-row items-center p-0 bg-gray/30 h-7' : 'p-2',
              editable
                ? 'cursor-pointer p-2 m-2 hover:border hover:rounded-lg hover:border-gray-btn hover:dark:border-white-soft'
                : '',
            ]"
          >
            <Skeleton v-if="loading" />
            <slot
              v-else
              name="cell"
              v-bind="{ data, field, value: data[field], index, currentPage, dataIndex: dataIndex(index) }"
            >
              {{ data[field] }}
            </slot>
          </div>
        </slot>
      </template>
      <template v-if="editable" #editor="{ data, field, index }">
        <slot name="edit-cell" v-bind="{ data, field, index, currentPage, dataIndex: dataIndex(index) }">
          {{ data[field] }}
        </slot>
      </template>
    </Column>
    <Column :header="LL.actions()" v-if="(actions || $slots.actions) && !loading">
      <template #body="{ data, index }">
        <div :class="[data.id === 'scope-cutoff' ? 'flex flex-row items-center h-7 p-0 bg-gray/30' : 'p-3']">
          <slot name="actions-wrapper" v-bind="{ data }">
            <div class="flex gap-2">
              <slot name="actions" v-bind="{ data, index }" v-if="data.id !== 'scope-cutoff'">
                <CGButton
                  :key="action"
                  v-for="{ icon, label, iconClass, action, iconDisabled } in actions"
                  @click="emit('action', { action, data, index, event: $event })"
                  icon-only
                  :icon
                  :disabled="disabled || iconDisabled"
                  :title="label"
                  :class="iconClass"
                />
              </slot>
              <slot name="custom-actions" v-bind="{ data, index }"></slot>
            </div>
          </slot>
        </div>
      </template>
    </Column>

    <template #empty><slot name="empty" /></template>
    <template #footer v-if="$slots.footer">
      <div class="p-3 border-t border-gray-border">
        <slot name="footer"></slot>
      </div>
    </template>
  </DataTable>
</template>
