<script setup lang="ts">
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'
// import { useClipboard } from '@vueuse/core'

const { LL, locale } = useLocales()
// const password = $ref('CodeGaiaESGReporting')
// const { copy } = useClipboard({ source: password })

const navigateToExternalPage = (type: string) => {
  if (type === 'guide') {
    window.open(
      locale.value === 'de'
        ? 'https://pitch.com/v/nutzungshandbuch_codegaia_ng-deutsch-wp5jaz'
        : 'https://docs.google.com/presentation/d/1XgafIZadNEEIJP07mYA6abN95mlwxOIARICliRzdGc0/edit#slide=id.g277acc1c4ef_0_1651',
      '_blank',
    )
  } else if (type === 'video') {
    window.open(
      `${LL.value.userMenu.academyInviteUrl()}&post_login_redirect=${encodeURIComponent('https://codegaiacommunity.circle.so/c/software-tutorials')}`,
      '_blank',
    )
  }
}
</script>

<template>
  <div class="grid place-content-center justify-items-center h-full">
    <h2 class="text-xl font-medium mb-4">{{ LL.onboarding.slide5.title0() }}</h2>
    <p class="w-[535px] text-center mb-8">{{ LL.onboarding.slide5.text0_1() }}</p>

    <h2 class="text-xl font-medium mb-4">{{ LL.onboarding.slide5.title1() }}</h2>
    <p class="w-[535px] text-center mb-8">{{ LL.onboarding.slide5.text1_1() }}</p>
    <p class="w-[535px] text-center mb-4">
      {{ LL.onboarding.slide5.text1_2() }}
    </p>

    <button
      class="flex py-2 px-3 items-center gap-2 rounded-lg bg-gray-btn font-medium text-black mb-4"
      @click="navigateToExternalPage('guide')"
    >
      <FormKitIcon icon="fileDoc" class="fill-black w-6" />
      <span>{{ LL.onboarding.slide5.button1() }}</span>
      <FormKitIcon icon="linkExternal" class="fill-black w-6" />
    </button>

    <button
      class="flex py-2 px-3 items-center gap-2 rounded-lg bg-gray-btn font-medium text-black mb-4"
      @click="navigateToExternalPage('video')"
    >
      <FormKitIcon icon="fileVideo" class="fill-black w-6" />
      <span>{{ LL.onboarding.slide5.button2() }}</span>
      <FormKitIcon icon="linkExternal" class="fill-black w-6" />
    </button>

    <p class="w-[535px] text-center">
      {{ LL.onboarding.slide5.text1_3() }}
    </p>
  </div>
</template>

<style scoped></style>
<route lang="yaml">
meta:
  locales:
    - userMenu
</route>
