<script setup lang="ts">
import { ref, computed, reactive } from 'vue'
import { isEmail } from '@/util/validation'
import { useRoute } from 'vue-router/auto'

import AppButton from '@component/forms/AppButton.vue'
import Dialog from '@/components/NewDialog.vue'
import TextField from '@component/forms/TextField.vue'
import InputField from '@component/QuestionnaireSection/InputField.vue'
import SelectBox, { type SelectBoxOption } from '@component/forms/SelectBox.vue'
import { FormKitIcon } from '@formkit/vue'
import UserOption from '@component/QuestionnaireSection/UserOption.vue'

import useLocales from '@composable/useLocales'
import { fetchUsers, fetchUser } from '@util/reporting'
import { useSnackStore } from '@store/snackStore'
import { type TaskCreateObj, useTasksStore } from '@/stores/tasks'
import { getKwargsForTasks, linkDataForTasks } from '@util/tasks'
import NestedSelect from '@component/NestedSelect.vue'
import type { TreeNode } from 'primevue/treenode'

export interface Props {
  showButton?: boolean
  reference?: string
  showDialogFromOutside?: boolean
  keyArgs: Record<string, string>
}

withDefaults(defineProps<Props>(), { showButton: true,showDialogFromOutside: false, })

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const { LL } = useLocales()
const snackBar = useSnackStore()
const tasksStore = useTasksStore()
const route = useRoute()

const linkData: TreeNode[] = linkDataForTasks(LL)

const showTaskAddDialog = ref(false)
const assignedUser = ref<SelectBoxOption | null>()

const linkDataValue = computed(() => {
  let routeName: string = route.name ?? ''
  const routeParams = route.params as { section: string }
  if (routeName.includes(':section') && routeParams.section) {
    routeName = routeName.replace(':section', routeParams.section)
  }
  return routeName
})
const isTaskPage = computed(() => route.name && route.name.includes('task'))

const taskFormData: TaskCreateObj = reactive({
  title: '',
  description: '',
  assignee_id: '',
  status: 'to-do',
  due_at: '',
  link_data: {
    name: linkDataValue.value,
  },
})
function resetAndClose() {
  taskFormData.assignee_id = ''
  taskFormData.title = ''
  taskFormData.description = ''
  taskFormData.due_at = ''
  taskFormData.link_data.name = linkDataValue.value
  assignedUser.value = undefined
  showTaskAddDialog.value = false
  emit('cancel')
}

const assignUser = async (user: string, external = false) => {
  if (external && !isEmail(user)) {
    snackBar.error(LL.value.taskModal.invalidEmail())
    return
  }
  taskFormData.assignee_id = user
  await setAssignedUser(taskFormData.assignee_id)
}

const createGeneralTask = async () => {
  if (taskFormData.assignee_id) {
    try {
      taskFormData.link_data.kwargs = getKwargsForTasks(__props.reference as string, __props.keyArgs)
      await tasksStore.createNewTask(taskFormData)
      snackBar.success(LL.value.taskModal.taskCreated())
      showTaskAddDialog.value = false
      resetAndClose()
    } catch (e) {
      console.error('Creating a task failed:', e)
      snackBar.error(LL.value.taskModal.failedToCreateTask())
    }
  }
}

async function setAssignedUser(userId: string | null = null) {
  if (userId) {
    assignedUser.value = await fetchUser(userId)
    return
  }
  assignedUser.value = taskFormData.assignee_id ? await fetchUser(taskFormData.assignee_id) : undefined
}

setAssignedUser()

function handleSelectLink(value: string) {
  taskFormData.link_data.name = value
}

function handleCtaClick() {
  showTaskAddDialog.value = true
  taskFormData.link_data.name = linkDataValue.value
}
</script>

<template>
  <AppButton
    v-if="showButton"
    :label="isTaskPage ? LL.taskModal.newTask() : LL.taskModal.addTask()"
    @click="handleCtaClick"
    :icon="isTaskPage ? 'addIcon' : 'tickSquareIcon'"
    :tab-btn="!isTaskPage"
    :primary="isTaskPage"
  />
  <Dialog
    v-if="showTaskAddDialog || showDialogFromOutside"
    type="confirm"
    static
    :header="''"
    max-width="435px"
    textColor="dark:text-white"
    no-footer
    @cancel="resetAndClose"
  >
    <template #header>
      <div class="w-full flex justify-between items-start">
        <div class="flex flex-col gap-2">
          <div class="font-bold text-lg">{{ LL.taskModal.heading() }}</div>
          <div class="text-sm text-gray-mid dark:text-white-soft">{{ LL.taskModal.info() }}</div>
        </div>
        <FormKitIcon
          class="w-10 rounded-md bg-gray-btn cursor-pointer dark:text-white z-50"
          icon="dialogClose"
          @click="resetAndClose()"
        />
      </div>
    </template>
    <FormKit type="form" @submit="createGeneralTask" :actions="false">
      <TextField class="mt-4 mb-2 -mt-2 w-100" required v-model="taskFormData.title" :label="LL.taskModal.title()" />
      <div class="w-100">
        <InputField
          type="textarea"
          :classes="'p-1'"
          :value="taskFormData.description as string"
          :label="LL.taskModal.details()"
          @update="taskFormData.description = $event as string"
        />
      </div>
      <div>
        <p class="text-xs pt-2">{{ LL.taskModal.link() }}</p>
        <NestedSelect :items="linkData" :value="taskFormData.link_data.name" @select="handleSelectLink" />
      </div>

      <SelectBox
        class="w-100 mb-4"
        autocomplete
        :options="fetchUsers"
        prefix-icon="search"
        :placeholder="LL.taskModal.searchUsers()"
        :label="LL.taskModal.selectAssignee()"
        :model-value="assignedUser"
        @input="assignUser(`${$event}`)"
        required
      >
        <template #selection="{ label, meta }" v-if="taskFormData.assignee_id">
          <UserOption :label="label" :sub-label="meta.email" :initials="meta.initials" :external="meta.external" />
          <button
            @click="
              () => {
                taskFormData.assignee_id = ''
                assignedUser = ''
              }
            "
          >
            <FormKitIcon icon="closeIcon" class="w-6" />
          </button>
        </template>
        <template #option="{ label, meta }">
          <UserOption big :label="label" :sub-label="meta.email" :initials="meta.initials" :external="meta.external" />
        </template>
        <!--          @TODO Uncomment when external users are enabled-->
        <!--      <template #actions="{ sublabel }">
        <UserOption big :label="LL.taskModal.assignExternal()" :sub-label="sublabel">
          <template #icon>
            <FormKitIcon icon="smsTracking" class="w-6" @click="assignUser(sublabel, true)" />
          </template>
        </UserOption>
      </template>-->
      </SelectBox>
      <TextField
        date
        class="mb-4 w-[60%] dark:text-white"
        required
        v-model="taskFormData.due_at"
        :min="new Date().toISOString().split('T')[0]"
        :label="LL.taskModal.dueDate()"
        :placeholder="LL.taskModal.selectDate()"
      />

      <div class="flex flex-row pt-2 justify-between items-start">
        <AppButton class="cursor-pointer" tab-btn :label="LL.cancel()" @click="resetAndClose" />
        <AppButton submit primary :label="LL.taskModal.createAndAssign()" />
      </div>
      <template #message></template>
    </FormKit>
  </Dialog>
</template>
